import React from 'react';
import ClassImage from "./../877665.png";
import overlayImage from "../../commonImg/overlay2-50.svg";
function Header({img,title,details}) {
    const st5={
        backgroundImage:`url(${ClassImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment:'initial'
    };
    const overlay={
        backgroundImage:`url(${overlayImage})`,
    };
    return(
        <header className="hero hero-fluid hero-page txt-color-sasha-white" style={st5}>
            <div style={overlay}>
                <div className="container nfloat-down">
                   <div className="row">
                       <div className="col-md-2"><img src={img} width="100px" height="100px" alt={title}/> </div>
                       <div className="col-md-10"> <h1>{title}</h1></div>
                   </div>
                    <p className="txt-size-lead" align="justify">{details}</p>
                </div>
            </div>
        </header>
    )
}
export default Header;
