import React,{Component} from 'react';
import Header from "./header";
import MyClass from "./myClass";
import {Helmet} from "react-helmet";
import ClassHeaderImage from "./classHeader.png";
import classData from './myClasses.json';
class Classes extends Component{

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div>
                <Helmet>
                    <title>Sashika Suraweera - Classes</title>
                    <meta name="description" content="Want to learn code ? Just join to my class" />
                    <meta name="keywords" content="sashika suraweera SASHIKA SURAWEERA colombo programming classes" />
                    <meta property="og:title" content="Sashika Suraweera - Classes"/>
                    <meta property="og:url" content="https://sashiksu.com/classes"/>
                    <meta property="og:image" content={ClassHeaderImage}/>
                </Helmet>
               {/*  <Header/> */}
            <Header/>
            <MyClass disabled={true}
                classTypes={classData.classTypes}
            />
            </div>
        )
    }
}

export default Classes;
