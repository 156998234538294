import React, { Component } from "react";
import Quote from "./quote";
import Education from "./education";
import Hello from "./hello";
import Technology from "./technologies";
import Welcome from "./welcome";
import Areas from "./areas";
import CurrentlyUsing from "./currentlyUsing";
import HomeHeaderImage from "./img/newImage5.jpg";
import { Helmet } from "react-helmet";
class Home extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Sashika Suraweera</title>
                    <meta name="description" content="" />
                    <meta name="keywords" content="Sashika Suraweera is a Software Engineer graduated from University of Plymouth on 2019 in Software Engineering. I mainly focused on backend API development using full stack technologies. Besides that I love to write." />
                    <meta property="og:title" content="Sashika Suraweera" />
                    <meta property="og:url" content="https://sashikasuraweera.com/" />
                    <meta property="og:image" content={HomeHeaderImage} />
                </Helmet>
                <Welcome />
                <CurrentlyUsing />
                <Areas />
                <Quote />
                <Education />
                <Hello />
                <Technology />
            </div>
        )
    }
}
export default Home
