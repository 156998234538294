import React,{Component} from "react";
import TableRow from "./tr";
import { Table} from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
class Technology extends Component{
    state={
      technologies:[
          {technology_id:0,technology:'Front-End',skills:'HTML, CSS, JavaScript, AJAX, UI Design, UX Improvement'},
          {technology_id:1,technology:'Mobile App',skills:'Android, React Native, Flutter'},
          {technology_id:2,technology:'Back-End',skills:'Node.js, Express.js, Django, REST API, .NET Core'},
          {technology_id:3,technology:'Database System',skills:'Oracle, MongoDB, SQLite, MySQL, MSSQL'},
          {technology_id:4,technology:'Software Architecture',skills:'MVC'},
          {technology_id:5,technology:'JS Framework/Library',skills:'React JS, Socket.io'},
          {technology_id:6,technology:'CSS Framework/Library',skills:'Bootstrap, Zurb Foundation'},
          {technology_id:7,technology:'Source Control',skills:'Git, BitBucket, GitHub, TFS'},
          {technology_id:8,technology:'Transpiler',skills:'Sass/SCSS, Babel'},
          {technology_id:9,technology:'Data Serialisation',skills:'JSON, XML'},
          {technology_id:10,technology:'Hosting Service',skills:'AWS, Heroku'},
          {technology_id:11,technology:'Programming',skills:'C#.NET, ASP.NET, Java, C++, VB.NET'},
          {technology_id:12,technology:'IDE, Text Editor',skills:'Atom, IntelliJ IDEA, JetBrains IDEs, Visual Studio, VSCode'},
          {technology_id:13,technology:'Graphic Design',skills:'Adobe Photoshop, CorelDraw'},
          {technology_id:14,technology:'OS',skills:'Windows, macOS, Linux - Ubuntu'},
      ]
    };
    render(){
        const tableDivStyles={
            marginTop: '30px'
        };
        return(
            <div className="home-component" id="technologies">
                <div className="container">
                    <h2 className="txt-color-primary">Technologies,</h2>
                    <p className="txt-size-lead">
                        Here are some technologies that I have used before on different projects.
                    </p>
                    <div className="table-container" style={tableDivStyles}>
                       {/* <table className="table table-bordered">
                            <TableRow technologies={this.state.technologies}/>
                        </table>*/}
                      {/*  <Table className="table table-bordered">*/}
                        <Table className="table table-striped table-hover">

                                <TableRow technologies={this.state.technologies}/>

                        </Table>
                    </div>
                </div>
            </div>
        )}}
export default Technology;