import React from "react";
import {uid} from "react-uid";
function ClassContent({classDetails}) {
    const contentJSX=classDetails.content && classDetails.content.map(content=>{
        return(
            <div key={uid(content)}>
                <li><b>{content.heading}</b></li>
                <p align="left">{content.description}</p>
            </div>
        )
    });
    const classAvailabilityJSX=()=>
    {
        let availability=classDetails.availability;
        if (availability==="Registering"){
            return(<div className="blink_me">Registering !&nbsp;</div>)
        }
        else{
            return(<div>{classDetails.availability}&nbsp;</div>)
        }
        //need to check and render other two availability status of class started and false
    };

    const seatDetailsJSX=()=>{
        return(<div>
            <h2><i className="fas fa-chair"></i>&nbsp;Seats,</h2>
            <h5 align="right">{classDetails.seats}&nbsp;</h5>
        </div>)
    };

    const bringItemsJSX=()=>{
        //const spanStyle={float:'left'};
        const itemList=classDetails.bringItems && classDetails.bringItems.map(item=>{
            const badgeColors=['sasha-greyest','sasha-darkest','primary','secondary','danger','info','success','warning'];
            const randomBadge=badgeColors[Math.floor(Math.random()*(classDetails.bringItems).length)];
            const badgeClassName='badge badge-'+ randomBadge;
            return(
                    <span  key={uid(item)}  className={badgeClassName}>{item}</span>
            )
        });
        return(
            <div>
                <h2><i className="fas fa-briefcase"></i>&nbsp;Bring,</h2>
                { itemList}
            </div>
        )
    };
    const aboutProjectsJSX=()=>{
      return(
          <div>
              <h2><i className="fas fa-rocket"></i>&nbsp;Projects,</h2>
             <p align="justify"> {classDetails.aboutProjects}</p>
          </div>
      )
    };
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <div className="" id="content">
                        <h2><i className="fas fa-list"></i>&nbsp;Content,</h2>
                        <ul type="square">
                            {contentJSX}
                        </ul>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="bg-sasha-greyest" id="payment">
                        <h2><i className="fas fa-money-bill-wave"></i>&nbsp;Payments,</h2>
                        <h4 align="right"> LKR. {classDetails.price}&nbsp;</h4>
                        <p align="right">{classDetails.paymentInstallments}&nbsp;</p>
                    </div><br/>
                    <div className="bg-sasha-greyer">
                        <h2><i className="far fa-clock"></i>&nbsp;Duration,</h2>
                        <h5 align="right">{classDetails.duration}&nbsp;</h5>
                    </div><br/>
                    <div className="bg-sasha-grey">
                        <h2><i className="fas fa-hourglass-start"></i>&nbsp;Enrollment,</h2>
                        <h5 align="right"> {classAvailabilityJSX()}&nbsp;</h5>
                    </div><br/>
                    <div className="">
                        <h2><i className="fas fa-mobile-alt"></i>&nbsp;Contact me,</h2>
                        <div>
                            Drop message on : <a href={"https://api.whatsapp.com/send?phone=94764228531&text=I want to know about , "+classDetails.className +' class'} target="_blank" rel="noopener noreferrer" className="btn btn-success-o btn-sm" aria-label="Edit"><h5>&nbsp;<i className="fab fa-whatsapp"></i>&nbsp;</h5></a>
                            <br/><hr/>
                            Send an email via : <a href={"mailto:sashiksu@gmail.com?subject="+classDetails.className} className="btn btn-primary-o btn-sm" aria-label="Edit"><h5>&nbsp;<i className="fas fa-envelope"></i>&nbsp;</h5></a><hr/>
                            Call me on : <font size="5">076 422 8531</font>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-md-2" id="seatDetails">{seatDetailsJSX()}</div>
                <div className="col-md-6">{
                    bringItemsJSX()
                }</div>
                <div className="col-md-4">{aboutProjectsJSX()}</div>
            </div>
            <hr/>
            <div className="row">
                <h2><i className="fas fa-location-arrow"></i>&nbsp;Class Location,</h2>
                <div className="col-md-12">
                    <iframe title="classLocation"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d914.7416848426498!2d80.00309757432404!3d6.83960919328526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2516fc35f617d%3A0x308abb163e5d7b26!2sNinaro%20Higher%20Education%20Institute!5e0!3m2!1sen!2slk!4v1577953094716!5m2!1sen!2slk"
                        width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen=""></iframe>
                </div>
            </div>
        </div>

    )
}
export default ClassContent