import React,{Component} from "react";
import {Link} from "react-router-dom";

class ClassBanner extends Component{
    constructor(props) {
        super(props);
        this.state={
            enTitle:this.props.enTitle,
            siTitle:this.props.siTitle,
            selectedTitle:this.props.enTitle,

            enText:this.props.enText,
            siText:this.props.siText,
            selectedText:this.props.enText,

            enBtn:this.props.enBtn,
            siBtn:this.props.siBtn,
            selectedBtn:this.props.enBtn,

            link:this.props.link
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e){
        let lan=e.target.value;
        if(lan==='en')
        this.setState({
            selectedTitle:this.props.enTitle,
            selectedText:this.state.enText,
            selectedBtn:this.props.enBtn,
        });
        else {
            this.setState({
                selectedTitle:this.props.siTitle,
                selectedText:this.state.siText,
                selectedBtn:this.props.siBtn,
            });
        }
    }
    render() {
        return (
            <div>
                <br/>
                <div className="hero hero-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="txt-weight-black">{this.state.selectedTitle}</h3>
                            </div>
                            {/*<div className="col-md-8">
                                <h3 className="txt-weight-black">{this.state.selectedTitle}</h3>
                            </div>
                            <div className="col-md-4">
                                <button className="btn btn-sasha-darkest" value="en" onClick={this.handleChange}>English</button>
                                &nbsp;
                                <button className="btn btn-sasha-darkest-o" value="si" onClick={this.handleChange}>සිංහල</button>
                            </div>*/}
                        </div>
                        <p className="txt-size-lead" align="justify">
                            {this.state.selectedText}
                        </p>
                        <Link className="btn btn-success btn-lg" to={"/classType/"+this.state.link} role="button">{this.state.selectedBtn}</Link>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
export default ClassBanner;
