import React,{Component} from "react";
import Quotes from "./quotes.json";
import QuoteImage from "./img/quote_img.png";
import overlayImage from "../commonImg/overlay2-50.svg";
class Quote extends Component {
    state={
        quote:Quotes.quotes,
        selectedQuote:[]
    };
    componentDidMount() {
        let quote = this.state.quote[Math.floor(Math.random()*this.state.quote.length)];
        this.setState(
            {
                selectedQuote:quote
            }
        );
    }
    render() {

        const divStyle={
            backgroundImage:`url(${QuoteImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        };
        const overlay={
            backgroundImage:`url(${overlayImage})`,
        };
        return (
            <div className="home-component txt-color-sasha-white" id="quote-machine" style={divStyle}>
                <div style={overlay}>
                    <div className="container txt-align-c">
                        <h4 className="txt-weight-light" id="quote-text">{this.state.selectedQuote.message}</h4>
                        <p className="txt-size-lead" id="quote-author">-&nbsp;{this.state.selectedQuote.who_said}</p>
                        <small className="txt-color-sasha-greyest">A random quote generator from my personalised list.</small>
                    </div>
                </div>
            </div>
        )
    }
}
export default Quote
