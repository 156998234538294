import React from 'react';
import WebImage from './img/types/web.svg';
import DesktopImage from './img/types/desktop.svg';
import OtherImage from './img/types/mobile.svg';
import RecentProjects from "./recentProjects";
import {Link} from "react-router-dom";

function ProjectType ({projectTypes,recentProjects}){

    const projectTypeList = projectTypes && projectTypes.map(projectType=>{
            let cardImage={};
            if(projectType.project_type==='web'){
                cardImage={
                    backgroundImage:`url(${WebImage})`
                };
            }else if (projectType.project_type==='desktop'){
                cardImage={
                    backgroundImage:`url(${DesktopImage})`
                };
            }else if(projectType.project_type==='other'){
                cardImage={
                    backgroundImage:`url(${OtherImage})`
                };
            }
            return(
                <div className="colProject" key={projectType.project_type_id}>
                    <Link to={'/projectList/'+projectType.project_type_id} >
                        <div className="containerProject">
                            <div className="front" style={cardImage}>
                                <div className="inner">
                                    <h3>{projectType.project_type_name}</h3>
                                    <span>Latest Projects</span>
                                </div>
                            </div>
                            <div className="back">
                                <div className="inner">
                                    {
                                        <RecentProjects recentProjects={recentProjects} projectType={projectType.project_type}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        }
    );
    return(
        <div className="wrapperProject">
            <div className="colsProject">
                {projectTypeList}
            </div>
        </div>
    )
}
export default ProjectType;