import React, { Component } from "react";
import Header from "./header";
import Content from "./content";
import { getArticle } from '../methods';
import { Helmet } from "react-helmet";
class BlogPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postId: this.props.match.params.post_id,
            selected: null
        };
    }
    componentDidMount = async () => {
        const articleResult = await getArticle(this.state.postId);
        if (articleResult) {
            this.setState({
                selected: articleResult
            })
        }
    }
    render() {
        const { selected } = this.state;
        let selectedArticle = selected;
        if (selected) {
            return (
                <div>
                    <Helmet>
                        <title>{selectedArticle.title}</title>
                        <meta name="description" content={selectedArticle.title} />
                        <meta name="keywords" content="blog, posts, medium posts" />
                        <meta property="og:title" content={selectedArticle.title} />
                        <meta property="og:url" content={window.location.hostname + this.props.history.location.pathname} />
                        <meta property="og:image" content={selectedArticle.thumbnail} />
                    </Helmet>
                    <Header
                        title={selectedArticle.title}
                        pubDate={selectedArticle.pubDate}
                        link={selectedArticle.link}
                        author={selectedArticle.author}
                        categories={selectedArticle.categories}
                    />
                    <Content content={selectedArticle.content} />
                </div>
            );
        }
        else {
            return (<div>
                <Header
                    title={"Loading"}
                    pubDate={"Loading"}
                    link={"Loading"}
                    author={"Loading"}
                    categories={"Loading"}
                />
                <Content content={"Loading"} />
            </div>);
        }
    }
}

export default BlogPost
