import React from 'react';
import GithubActivity from "./activityGH";
import loadingImage from "../common/img/loading.gif";
function Github ({activities,loading}) {

    let activityGH;
    if(loading===false){
        activityGH=(
            <div className="container">
                <h2><i className="fab fa-github"></i>&nbsp;Github,</h2>
                <br/>
                <img src={loadingImage} width="160" height="160" alt="loading gif animation"/>
            </div>
        )
    }
    else{
        activityGH=(
            <div className="container">
                <h2><i className="fab fa-github"></i>&nbsp;Github,</h2>
                <br/>
                <GithubActivity githubActivities={activities}/>
            </div>
        )
    }return activityGH;
}
export default Github;