import React from "react";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {uid} from "react-uid";
import {Link} from "react-router-dom";
function MobileAndTabView({data,techStackGenerate}) {
    const tableRowJSX=data && data.map(tr=>{
        return(
            <Tr key={uid(tr)}>
                <Td>{tr.project_name}</Td>
                <Td><div>{techStackGenerate(tr.technology_stack)}</div></Td>
                <Td> <div><Link to={"/project/" + tr.project_id} className="btn btn-primary btn-sm"><i className="fa fa-info" aria-hidden="true"></i>&nbsp;Info</Link></div></Td>
            </Tr>
        );
    });
    return(
        <Table className="table table-striped table-bordered table-hover">
            <Thead>
                <Tr>
                    <Th>Name</Th>
                    <Th>Tech Stack</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            <Tbody>
                {tableRowJSX}
            </Tbody>
        </Table>
    )
}
export default MobileAndTabView;