import React from 'react';
function RecentProjects({recentProjects,projectType}) {
    let myProjects=recentProjects;
    if(projectType==='web'){
        myProjects=recentProjects.filter(recentProjects=>{
            return recentProjects.project_type==='web';
        });
    }else if(projectType==='desktop'){
        myProjects=recentProjects.filter(recentProjects=>{
            return recentProjects.project_type==='desktop';
        });
    }
    else if(projectType==='other'){
        myProjects=recentProjects.filter(recentProjects=>{
            return recentProjects.project_type==='other';
        });
    }
    const recentProjectList=myProjects.map(project=>{
        return(
            <li key={project.project_id}>{project.project_name}</li>
        )
    });
    return(
        <ul type="square">
            {recentProjectList}
        </ul>
    )
}
export default RecentProjects;
//no need to update with redux