import React,{Component} from "react";
import PlyUniLogo from './img/education/hau.png';
import HarvardEdxLogo from './img/education/harvardx.png';
import UdacityLogo from './img/education/udacity.png';
import UdemyLogo from './img/education/udemy-new.png';
import FreeCodeCampLogo from './img/education/freecodecamp.svg';
import YoutubeLogo from './img/education/youtube.png';
class Education extends Component{
    render(){
        return(
            <div className="home-component" id="education">
                <div className="container">
                    <h2 className="txt-color-primary">Education</h2>
                    <p className="txt-size-lead" align="justify">
                        I studied <strong>Software Engineering</strong> at University of Plymouth. In my pursuit to never stop
                        learning,
                        I’m constantly improving my skills by taking courses such as <em>Harvard’s CS50 via edX</em> for
                        stronger Computer Science fundamentals,
                        and on <em>Udacity</em> and <em>Udemy</em> mainly for general programming.
                    </p>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4 col-lg-2">
                            <img className="img-fluid img-grayscale" src={PlyUniLogo}
                                 alt="Holy Angel University"/>
                        </div>
                        <div className="col-sm-4 col-lg-2">
                            <img className="img-fluid img-grayscale" src={HarvardEdxLogo}
                                 alt="HarvardX CS50 via edX"/>
                        </div>
                        <div className="col-sm-4 col-lg-2">
                            <img className="img-fluid img-grayscale" src={UdacityLogo}
                                 alt="Udacity"/>
                        </div>
                        <div className="col-sm-4 col-lg-2">
                            <img className="img-fluid img-grayscale" src={UdemyLogo}
                                 alt="Udemy"/>
                        </div>
                        <div className="col-sm-4 col-lg-2">
                            <img className="img-fluid img-grayscale" src={FreeCodeCampLogo}
                                 alt="Free Code Camp"/>
                        </div>
                        <div className="col-sm-4 col-lg-2">
                            <img className="img-fluid img-grayscale" src={YoutubeLogo}
                                 alt="YouTube"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }}
export default Education
