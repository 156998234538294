import React from 'react';
import NavBar from "./components/common/navbar";
import Footer from "./components/common/footer";
import Home from "./components/home/home";
import { BrowserRouter, Route, Switch} from "react-router-dom";
import Now from "./components/now/now";
import Writings from "./components/writings/writings";
import Projects from "./components/projects/projects";
import Classes from "./components/classes/classes";
import About from "./components/about/about";
import PrivacyPolicy from "./components/common/privacy";
import ProjectList from "./components/projects/projectList/projectList";
import Project from "./components/projects/project/project";
import Class from "./components/classes/class/class";
import ClassType from "./components/classes/classType/classType";
import BlogPost from "./components/writings/blogPost/blogPost";
import NotFound from "./components/common/notFound";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <NavBar/>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/now" component={Now}/>
                    <Route path="/writings" component={Writings}/>
                    <Route path="/projects" component={Projects}/>
                    <Route path="/classes" component={Classes}/>
                    <Route path="/about" component={About}/>
                    <Route path="/privacy" component={PrivacyPolicy}/>
                    <Route path="/projectList/:project_type" component={ProjectList}/>
                    <Route path="/project/:project_id" component={Project}/>
                    <Route path="/class/:class_id" component={Class}/>
                    <Route path="/classType/:class_type" component={ClassType}/>
                    <Route path="/post/:post_id" component={BlogPost}/>
                    <Route path="*" component={NotFound}/>
                </Switch>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}
export default App;
