import React, { Component } from 'react';
import Header from "./header";
import ProjectContent from "./projectContent";
import ProjectData from '../projectsData.json'

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingImage: false,
            selectedProject: ProjectData.projects.find(project => project.project_id === this.props.match.params.project_id)
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { selectedProject } = this.state;
        return (
            <div>
                <Header title={selectedProject.project_name} shortDescription={selectedProject.short_description} loadingImage={this.state.loadingImage} />
                <ProjectContent content={selectedProject} />
            </div>
        );
    }
}

export default Project;