import React from 'react';
import {uid} from 'react-uid';
function Article({articles}) {

    const articleList = articles && articles.map(article=>{
            const anchorStyles={
                color: 'inherit',
                textDecoration: 'inherit'
            };

            const spanStyle={
                display: 'inline-block'
            };
            const badgeList = article.categories && article.categories.map(badge=>{
                const badgeColors=['sasha-greyest','sasha-darkest','primary','secondary','danger','info','success','warning'];
                const randomBadge=badgeColors[Math.floor(Math.random()*8)];
                const badgeClassName='badge badge-'+randomBadge;
                return(
                    <span style={spanStyle} className={badgeClassName} key={uid(badge)}>{badge}&nbsp; </span>
                )
            });
            return(
        /*        <a className="cards" style={anchorStyles} href={article.guid} target="_blank" rel="noopener noreferrer" key={uid(article)}>*/
                <a className="cards" style={anchorStyles} href={"/post/"+(article.guid).substring('https://medium.com/p/'.length)} key={uid(article)}>
                    <div className="card-item">
                        <div className="card-image">
                            <img className="img-fluid" src={article.thumbnail} alt={article.title}/>
                        </div>
                        <div className="card-info">
                            <h2 className="card-title">{article.title}</h2>
                            <p className="card-intro">{badgeList}</p>
                        </div>
                    </div>
                </a>
            )
        }
    );
    return(
        <div className="card-container">
            {articleList}
        </div>
    )
}
export default Article;
