import React from "react";
import {uid} from "react-uid";

function Event({events}) {


    const myTimelineList=events.map(event=>{
        let eventJSX=null;
       if(event.type==="start" && event.event_id===1){
           eventJSX=(
               <div key={uid(event)}>
                   <div className="timeline-row align-items-center how-it-works">
                       <div className="col-2 text-center bottom">
                           <div className="circle">{event.event_id}</div>
                       </div>
                       <div className="col-6">
                           <h5>{event.title}</h5>
                           <p align="justify">{event.description}</p>
                       </div>
                   </div>
                   <div className="timeline-row timeline">
                       <div className="col-2">
                           <div className="corner top-right"></div>
                       </div>
                       <div className="col-8">
                           <hr/>
                       </div>
                       <div className="col-2">
                           <div className="corner left-bottom"></div>
                       </div>
                   </div>
               </div>
           )
       }
       else if(event.type==="end") {
           let reminder=event.event_id % 2;
           if(reminder===0){
               eventJSX=(
                   <div key={uid(event)}>
                       <div className="timeline-row align-items-center justify-content-end how-it-works">
                           <div className="col-6 text-right">
                               <h5>Future...</h5>
                               <p align="justify">{event.description}</p>
                           </div>
                           <div className="col-2 text-center full">
                               <div className="circle">{event.event_id}</div>
                           </div>
                       </div>
                   </div>
               )
           }
           else if(reminder===1){
               eventJSX=(
                   <div key={uid(event)}>
                       <div className="timeline-row align-items-center how-it-works">
                           <div className="col-2 text-center">
                               <div className="circle">{event.event_id}</div>
                           </div>
                           <div className="col-6">
                               <h5>Future...</h5>
                               <p align="justify">{event.description}</p>
                           </div>
                       </div>
                   </div>
               )
           }
       }
       else if(event.type==="left"){
           eventJSX=(
               <div key={uid(event)}>
                   <div className="timeline-row align-items-center justify-content-end how-it-works">
                       <div className="col-6 text-right">
                           <h5>{event.title}</h5>
                           <p align="justify">{event.description}</p>
                       </div>
                       <div className="col-2 text-center full">
                           <div className="circle">{event.event_id}</div>
                       </div>
                   </div>
                   <div className="timeline-row timeline">
                       <div className="col-2">
                           <div className="corner right-bottom"></div>
                       </div>
                       <div className="col-8">
                           <hr/>
                       </div>
                       <div className="col-2">
                           <div className="corner top-left"></div>
                       </div>
                   </div>
               </div>
           )
       }
       else if(event.type==="right"){
           eventJSX=(
               <div key={uid(event)}>
                   <div className="timeline-row align-items-center how-it-works">
                       <div className="col-2 text-center full-left">
                           <div className="circle">{event.event_id}</div>
                       </div>
                       <div className="col-6">
                           <h5>{event.title}</h5>
                           <p align="justify">{event.description}</p>
                       </div>
                   </div>
                   <div className="timeline-row timeline">
                       <div className="col-2">
                           <div className="corner top-right"></div>
                       </div>
                       <div className="col-8">
                           <hr/>
                       </div>
                       <div className="col-2">
                           <div className="corner left-bottom"></div>
                       </div>
                   </div>
               </div>
           )
       }
       return eventJSX;
    }
    );

return(
    <div className="container" >
        {myTimelineList}
    </div>
)
}
export default Event