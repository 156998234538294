import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from './img/404_not_found.png';

class NotFound extends Component{
    constructor(props) {
        super(props);
        this.timeoutHandle = setTimeout(()=>{
            this.props.history.push("/");
        }, 0);//set this 1000 for 1 second wait
    }
    render() {
        return(
            <div>
                <img src={PageNotFound} style={{width: 400, height: 400, display: 'block', margin: 'auto', position: 'relative' }} alt="404 not found !"/>
                <center><Link to="/">Return to Home Page</Link></center>
            </div>
        )
    }
}
export default NotFound;
