import React,{Component} from "react"
import Header from "./header";
import ClassContent from "./classContent";
import {Helmet} from "react-helmet";
class Class extends Component{
    constructor(props) {
        super(props);
        this.state={
            selectedClass:this.props.registeringClasses && this.props.registeringClasses.filter(myClass=>{return myClass.classId===this.props.match.params.class_id})
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const selectedClass=this.state.selectedClass[0];
        return(
            <div>
                <Helmet>
                    <title>{selectedClass.className}</title>
                    <meta
                        name="description"
                        content={selectedClass.details}
                    />
                    <meta
                        name="keywords"
                        content={selectedClass.details}
                    />
                </Helmet>
                <Header img={selectedClass.img} title={selectedClass.className} details={selectedClass.details}/>
                <ClassContent classDetails={this.state.selectedClass[0]}/>
            </div>
        )
    }
}

export default Class;