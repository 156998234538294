import React, { Component } from "react";
import axios from "axios";
import HelloImage from "./img/hello_img.png";
class Hello extends Component {

    state = {
        countryName: null,
    };

    componentDidMount() {
        axios.get('https://geolocation-db.com/json/')
            .then(geoLocationRes => {
                console.log(geoLocationRes)
                this.setState({
                    countryName: geoLocationRes.data.country_name
                });
                if (geoLocationRes.data.IPv4) {
                    axios.post('http://213.136.85.19:6002/api/v1/visitor', { ...geoLocationRes.data })
                        .then(res => {
                        })
                }
            });

    };
    render() {
        const divStyles = {
            backgroundColor: '#171719 ',
            backgroundImage: `url(${HelloImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        };
        const { countryName } = this.state;
        return (
            <div className="home-component txt-color-sasha-white" id="greetings" style={divStyles}>
                <div className="container txt-align-c">
                    <h3 className="txt-weight-light" id="greetings-country">hey <i className="fas fa-hand-spock"></i>, visitor from {countryName} !</h3>
                </div>
            </div>
        )
    }
}
export default Hello
