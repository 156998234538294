import React,{Component} from "react";
import ClassBanner from "./classBanner";
class MyClass extends Component {
    constructor(props){
        super(props);
        console.profile(props);
        this.state={
            classTypes:props.classTypes
        };
    }

    render() {
        const {classTypes}=this.state;
        const {disabled}=this.props;
        const classTypesJSX=classTypes && classTypes.map(classType=>{
            return(
                <ClassBanner
                    key={classType.classTypeId}
                    enTitle={classType.enTitle}
                    siTitle={classType.siTitle}
                    enText={classType.enText}
                    siText={classType.siText}
                    enBtn={classType.enBtn}
                    siBtn={classType.siBtn}
                    link={classType.link}
                />
            )
        });

        return (
            <div style={disabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                <br/>
                <center><h5>Currently classes are unavailable !</h5></center>
                {classTypesJSX}
            </div>
        );

    }
}
export default MyClass
