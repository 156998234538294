import React from 'react';
import WelcomeImage from "./writingHeader.jpg";
import overlayImage from "../commonImg/overlay2-50.svg";
function Header() {
    const heroStyle={
        backgroundImage:`url(${WelcomeImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment:'initial'
    };
    const overlay={
        backgroundImage:`url(${overlayImage})`,
    };
    const headerStyle={
        textShadow:'1px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff'
    };
    return(
        <header className="hero hero-fluid hero-page txt-color-sasha-darkest" style={heroStyle}>
            <div style={overlay}>
                <div className="container nfloat-down">
                    <h1 className="txt-weight-black" style={headerStyle}>My Latest Writings on MEDIUM</h1>
                    <h5><span className="badge badge-sasha-darkest">To discover my all writings visit medium</span></h5>
                    <a className="btn btn-sasha-darkest-o" href="https://medium.com/@sashiksu" target="_blank" role="button" rel="noopener noreferrer">Read Other Writings</a>
                </div>
            </div>
        </header>
    )
}
export default Header;
