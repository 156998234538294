import React, { Component } from "react";
//import WelcomeImage from './img/P8310013.svg';
import WelcomeImage from './img/newImage5.jpg';
import LoadingPlaceholderImage from './img/loadingPlaceholder.png';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
class Welcome extends Component {
    render() {
        const headerStyle = {
            backgroundImage: `url(${WelcomeImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundAttachment: 'initial'
        };
        const whiLoadingStyle = {
            backgroundImage: `url(${LoadingPlaceholderImage})`,
            backgroundRepeat: 'repeat'
        };
        const whileLoading = (
            <header className="hero hero-fluid hero-home txt-color-sasha-white txt-align-c" style={whiLoadingStyle}>
                <div className="container nfloat-down">

                    <h1 style={{ WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '1px', color: 'black' }}><strong>SASHIKA SURAWEERA</strong></h1>
                    <p className="txt-size-lead">
                        I focus on <strong>BACKEND</strong> Web Development
                        using <em>FullStack</em> technologies <br />My favourite is <em>MERN Stack</em>.
                    </p>
                    <div className="social-links">
                        <a href="https://facebook.com/sashiksu" target="_blank" className="social social-facebook" rel="noopener noreferrer"
                            title="Be my friend.">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://instagram.com/sashiksu" target="_blank" className="social social-instagram" rel="noopener noreferrer"
                            title="See my photo gallery.">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="https://twitter.com/sashiksu" target="_blank" className="social social-twitter" rel="noopener noreferrer"
                            title="See my tweets.">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://linkedin.com/in/sashiksu" target="_blank" className="social social-linkedin" rel="noopener noreferrer"
                            title="Send requests, let's connect.">
                            <i className="fab fa-linkedin-in"></i>
                        </a>
                        <a href="https://github.com/sashiksu" target="_blank" className="social social-github" rel="noopener noreferrer"
                            title="Let’s contribute to open source.">
                            <i className="fab fa-github"></i>
                        </a>
                        <a href="https://goodreads.com/sashiksu" target="_blank" className="social social-goodreads" rel="noopener noreferrer"
                            title="I love reading!">
                            <i className="fab fa-goodreads"></i>
                        </a>
                        <a href="https://www.quora.com/profile/Sashika-Suraweera" target="_blank" className="social social-quora" rel="noopener noreferrer"
                            title="Let's share our experience !">
                            <i className="fab fa-quora"></i>
                        </a>
                        <div className="clearfix"></div>
                    </div>

                </div>
            </header>
        );
        return (
            <LazyLoadComponent
                placeholder={whileLoading}>
                <header className="hero hero-fluid hero-home txt-color-sasha-white txt-align-c" style={headerStyle}>
                    <div className="container nfloat-down">
                        <h1><strong>SASHIKA SURAWEERA</strong></h1>
                        <p className="txt-size-lead">
                            I focus on <strong>BACKEND</strong> Web Development
                            using <em>FullStack</em> technologies <br />My favourite is <em>MERN Stack</em>.
                        </p>
                        <div className="social-links">
                            <a href="https://facebook.com/sashiksu" target="_blank" className="social social-facebook" rel="noopener noreferrer"
                                title="Be my friend.">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="https://instagram.com/sashiksu" target="_blank" className="social social-instagram" rel="noopener noreferrer"
                                title="See my photo gallery.">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://twitter.com/sashiksu" target="_blank" className="social social-twitter" rel="noopener noreferrer"
                                title="See my tweets.">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="https://linkedin.com/in/sashiksu" target="_blank" className="social social-linkedin" rel="noopener noreferrer"
                                title="Send requests, let's connect.">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                            <a href="https://github.com/sashiksu" target="_blank" className="social social-github" rel="noopener noreferrer"
                                title="Let’s contribute to open source.">
                                <i className="fab fa-github"></i>
                            </a>
                            <a href="https://goodreads.com/sashiksu" target="_blank" className="social social-goodreads" rel="noopener noreferrer"
                                title="I love reading!">
                                <i className="fab fa-goodreads"></i>
                            </a>
                            <a href="https://www.quora.com/profile/Sashika-Suraweera" target="_blank" className="social social-quora" rel="noopener noreferrer"
                                title="Let's share our experience !">
                                <i className="fab fa-quora"></i>
                            </a>
                            <div className="clearfix"></div>
                        </div>
                        <p>.copyright&#123;img-src:'unisci24.com'&#125;</p>
                    </div>
                </header>
            </LazyLoadComponent>
        )
    }
}
export default Welcome;
