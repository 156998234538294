import React from "react";
import Copyright from "./copyright";
import ParticleComponent from './particleComponent';

/*class Footer extends Component {*/
function Footer(props) {
    return (
        <div
            style={{
                position: "relative",
                top: 0,
                /*left: 0,*/
                width: "100%",
                zIndex: "1"
            }}
        >
            <footer className="footer-bs">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 footer-brand animated fadeInLeft">
                            <h2>@sashiksu</h2>
                            <p align="justify">
                                My name is Sashika Suraweera.
                                I was graduated from  University of Plymouth on 2019 in Software Engineering.
                                I'm Software Engineer at USA based software development company & a visiting lecturer at private institute.
                                I develop applications and teach others how to build applications. Besides that I'm
                                writing.
                                    </p>
                        </div>
                        <div className="col-md-6 footer-nav animated fadeInUp">
                            <h4>More from @sashiksu —</h4>
                            <div className="col-md-6">
                                <ul className="pages">
                                    {/*   <li><a href="https://store.sashiksu.com" target="_blank"
                                        rel="noopener noreferrer">Shopping With Me</a></li> */}
                                    <li><a href="https://www.adfinder.lk" target="_blank"
                                        rel="noopener noreferrer">adfinder - UPGRADING !</a></li>
                                    <li><a href="https://play.google.com/store/apps/details?id=com.sashiksu.aruth" target="_blank"
                                        rel="noopener noreferrer">Aruth - The Dictionary</a></li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="list">
                                    <li><a href="mailto:sashiksu@gmail.com?subject=Let's get know">Contact Me</a></li>
                                    <li><a href="/">Terms & Condition</a></li>
                                    <li><a href="/">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 footer-social animated fadeInDown">
                            <div>
                                <h4>Other Links</h4>
                                <ul>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Copyright />
            </footer>
            <ParticleComponent />
        </div>
    )
}
export default Footer;
