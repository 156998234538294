import React, { Component } from 'react';
import Article from "./article";
import loadingImage from "../common/img/loading.gif";
import { getArticles } from "./methods";

class Content extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            articles: [],
        };
    }
    componentDidMount = async () => {
        const articleResult = await getArticles();
        if (articleResult) {
            this.setState({
                loading: true,
                articles: articleResult,
            })
        }
    }

    render() {
        const { loading, articles } = this.state;
        let articlesJSX;
        if (loading === false) {
            articlesJSX = (
                <div className="container">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <img src={loadingImage} width="160" height="160" alt="loading gif animation" />
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            )
        }
        else {
            articlesJSX = (
                <div className="container" >
                    <Article articles={articles} />
                </div>
            )
        }
        return articlesJSX;
    }
}

export default Content;