import React from "react"
import loadingImage from "../../common/img/loading.gif";
import {uid} from "react-uid";
function ProjectContent({content}) {
    const spanStyle={
        display: 'inline-block'
    };
    const imgStyles={
        maxWidth:'100%',
        maxHeight:'100%'
    };
    const portrait={
        width:'300px',
        height:'600px'
    };
    const landscape={
        width:'700px',
        height:'400px'
    };
    const techStackList=content.technology_stack && content.technology_stack.map(tech=>{
        const badgeColors=['sasha-greyest','sasha-darkest','primary','secondary','danger','success','warning'];
        const randomBadge=badgeColors[Math.floor(Math.random()*7)];
        const badgeClassName='badge badge-'+ randomBadge;
        return(
            <div style={{float:'left'}} key={uid(tech)}>
                <h5><span key={Math.random()} style={spanStyle} className={badgeClassName}>{tech}</span>&nbsp;</h5>
            </div>
        )
    });
    /*description paragraph*/
    const paragraphJSX=content.description && content.description.map(paragraph=>{
        return(
            <p key={uid(paragraph)} align="justify">{paragraph}</p>
        )
    });
    /*description paragraph*/

    let image=(
        <img src={loadingImage} width="160" height="160" alt="loading gif animation"/>
    );

    if(content.image){
        if(content.image_type==='portrait'){
            image=(
                <div style={portrait}>
                    <img style={imgStyles} className="img-fluid" src={content.image} alt={content.project_name+' photo'}/>
                </div>
            )
        }else {
            image=(
                <div style={landscape}>
                    <img style={imgStyles} className="img-fluid" src={content.image} alt={content.project_name+' photo'}/>
                </div>
            )
        }
    }
    /*repo source code*/
    let repoJSX=(<h5><span className="badge badge-info">Private Repository</span></h5>);
    if(content.github !== "")
    {
        repoJSX= (<a href={content.github} target="_blank" rel="noopener noreferrer"><h5><span className="badge badge-info">View Source</span></h5></a>)
    }
    /*repo source code*/

    /*web hosted code*/
    let liveJSX=(<h5><span className="badge badge-info">Site is not hosted</span></h5>);
    if(content.web !== "")
    {
        liveJSX= (<a href={content.web} target="_blank" rel="noopener noreferrer"><h5><span className="badge badge-info">View</span></h5></a>)
    }
    /*web hosted  code*/

    /*download code*/
    let downloadJSX=(<h5><span className="badge badge-info">No download file</span></h5>);
    if(content.web !== "")
    {
        downloadJSX= (<a href={content.web} target="_blank" rel="noopener noreferrer"><h5><span className="badge badge-info">Downlaod</span></h5></a>)
    }
    /*download  code*/


    return(
        <div className="container">

            <div className="row">
                <div className="col-md-6">
                    <h5><i className="fas fa-align-left"></i>&nbsp;Description,</h5>
                    {paragraphJSX}
                </div><br/>
                <div className="col-md-6">
                    {image}
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-md-12">
                    <h5><i className="fas fa-cogs"></i>&nbsp;Technology Stack,</h5>
                    {techStackList}
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-md-4">
                    <h5><i className="fab fa-github"></i>&nbsp;Github,</h5>
                    {repoJSX}
                </div>

                <div className="col-md-4">
                    <h5><i className="fab fa-internet-explorer"></i>&nbsp;Web,</h5>
                    {liveJSX}
                </div>

                <div className="col-md-4">
                    <h5><i className="fas fa-download"></i>&nbsp;Download,</h5>
                    {downloadJSX}
                </div>
            </div>
            <hr/>
        </div>
    )
}
export default ProjectContent