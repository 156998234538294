import React,{Component} from 'react';
import BackgroundImage from "./background.svg";
import myEvents from "./myTimeline";
import Event from "./event";
class Timeline extends Component{
    constructor(props) {
        super(props);
        this.state={
            allEvents:myEvents.timeline,
            showStyle:{height:'540px',overflow:'hidden'},
            show:false,
            btnText:'More'
        };
        this.showMore = this.showMore.bind(this);
    }

    showMore(){
        if(this.state.show===true){
            this.setState({
                show:false,
                showStyle:{},
                btnText:'Hide'
            })
        }
        else {
            this.setState({
                show:true,
                showStyle:{height:'340px',overflow:'hidden'},
                btnText:'More'
            })
        }
    }
    componentDidMount() {
        this.showMore();
    }

    render() {
        const backgroundStyles={
            backgroundImage:`url(${BackgroundImage})`
        };
        return(
            <div style={backgroundStyles}>
                <div style={this.state.showStyle}>
                    <Event events={this.state.allEvents}/>
                </div>
                <br/>
                <div align="center">
                    <button className="btn btn-sasha-darkest-o" onClick={this.showMore}>{this.state.btnText}</button>
                </div>
                <br/>
            </div>
        )
    }
}
export default Timeline;