import React from "react";
import {NavLink} from 'react-router-dom'
function NavBar(){
    return(
        <nav className="nav nav-sasha-darkest nav-fixed nav-t">
            <div className="container">
                <div className="nav-toggler nav-toggler-r">
                    <div className="nav-icon nav-icon-elastic-r">
                        <div className="nav-icon-box">
                            <div className="nav-icon-rod"></div>
                        </div>
                    </div>
                </div>
                <a href="/" className="nav-logo">
                    <span className="txt-weight-normal"><strong>Sashik</strong>a<strong> Su</strong>raweera</span>
                </a>
                <div className="nav-menu">
                    <ul className="nav-menu-l">
                        <li>
                            <NavLink to="/now">
                                <i className="fas fa-clock"></i>
                                &nbsp;Now
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/writings">
                                <i className="fas fa-pen-alt"></i>
                                &nbsp;Writings
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/projects">
                                <i className="fas fa-rocket"></i>
                                &nbsp;Projects
                            </NavLink>
                        </li>
                      <li>
                            <NavLink to="/classes">
                                <i className="fas fa-mail-bulk"></i>
                                &nbsp;Code With Me
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/about">
                                <i className="fas fa-user"></i>
                                &nbsp;About
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default  NavBar
