import React,{Component} from 'react'
import Header from "../about/header";
import Timeline from "./timeline";
import {Helmet} from "react-helmet";
import AboutHeaderImage from './aboutHeader.png'
class About extends Component{
    render() {
        return(
            <div>
                <Helmet>
                    <title>Sashika Suraweera - About</title>
                    <meta name="description" content="My name is Sashika Suraweera. I'm Software Engineering graduate from University of Plymouth. I develop applications. See more information via Now, Writings, Projects and About sections. If you wanted to learn application development please check Join To Classes section." />
                    <meta name="keywords" content="sashika suraweera SASHIKA SURAWEERA" />
                    <meta property="og:title" content="Sashika Suraweera - About"/>
                    <meta property="og:url" content="https://sashiksu.com/about"/>
                    <meta property="og:image" content={AboutHeaderImage}/>
                </Helmet>
                <Header/>
                <Timeline/>
            </div>
        )
    }
}
export default About;
