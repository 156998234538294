import React,{Component} from "react";
import Header from "./header";
import TableGroup from "../tableGroup";
import {Helmet} from "react-helmet";
class ClassType extends Component{
    constructor(props) {
        super(props);
        this.state={
            classType:this.props.match.params.class_type,
            classes:this.props.classes && this.props.classes.filter(aClass=>{return aClass.classType===this.props.match.params.class_type}),
            selectedClassType:this.props.classTypes && this.props.classTypes.filter(aType=>{return aType.classTypeId===this.props.match.params.class_type})
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const selectedClassType=this.state.selectedClassType[0];
        const {classType,classes}=this.state; //grab the class type from url | add other class types here and destructure those here

        let tableGroupJSX;
        //check each class type and send relevant class details via classData prop below
        if(classType==="after-OLs-and-ALs"){
            tableGroupJSX=(
                <TableGroup
                    title={"After A/Ls and O/Ls"}
                    classesData={classes}
                />
            )
        }else if(classType==="campus-students"){
            tableGroupJSX=(
                <TableGroup
                    title={"Computing Undergraduate Subjects"}
                    classesData={classes}
                />
            )
        }else if(classType==="advanced-programming-classes"){
            tableGroupJSX=(
                <TableGroup
                    title={"Advance Programming Classes"}
                    classesData={classes}
                />
            )
        }
        const classTypeJSX=(
            <div>
                {tableGroupJSX}
            </div>
        );
        const classAlertJSX=(
            <div className="alert alert-info" role="alert" style={{ display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                <strong>Register from here !</strong> &nbsp; &nbsp; &nbsp; &nbsp;
                <a  className="btn btn-sasha-darkest float-r"  rel="noopener noreferrer" data-close="alert"  target="_blank" href={selectedClassType.registrationLink}>Click</a>
                <div className="clearfix"></div>
            </div>
        );
        return(
            <div>
                <Helmet>
                    <title>{selectedClassType.enTitle}</title>
                    <meta
                        name="description"
                        content={selectedClassType.enText}
                    />
                    <meta
                        name="keywords"
                        content={selectedClassType.enText}
                    />
                </Helmet>
                <Header title={selectedClassType.enTitle}/>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h5 align="justify" className="txt-weight-light">{selectedClassType.enText}</h5>
                        </div>
                        <div className="col-md-4">
                            {classAlertJSX}
                        </div>
                    </div>
                    {classTypeJSX}
                </div>
            </div>
        )
    }
}

export default ClassType
