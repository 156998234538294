import axios from 'axios';

export const getArticles = async () => {
    try {
        const result = await axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sashiksu");
        return result.data.items;
    } catch (err) {
        return [];
    }
}

export const getArticle = async (post_id) => {
    try {
        let results = await axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sashiksu");
        results = results.data.items;
        return results.find(article => article.guid === ("https://medium.com/p/" + post_id));
    } catch (err) {
        return [];
    }
}