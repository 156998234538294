import React from "react";
import {Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

function TableRow({technologies}){
    const technologyList=technologies.map(technology=>{
            return(
                <Tr key={technology.technology_id}>
                    <Th>{technology.technology}</Th>
                    <Td>{technology.skills}</Td>
                </Tr>
            )
        }
    );
    return(
        <Tbody>
            {technologyList}
        </Tbody>
    )
}
export default TableRow