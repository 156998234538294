import React from 'react';
import BackgroundImage from "./filler.png";
import overlayImage from "../../commonImg/overlay2-50.svg";
function Header({title,shortDescription}) {
    const style={
        backgroundImage:`url(${BackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    };
    const overlay={
        backgroundImage:`url(${overlayImage})`,
    };
    return(
        <header className="hero hero-fluid hero-page txt-color-sasha-white animated-project-hero" style={style}>
            <div style={overlay}>
                <div className="container nfloat-down">
                    <h1>{title}</h1>
                    <p>{shortDescription}</p>
                </div>
            </div>
        </header>
    )
}
export default Header;
