import React from 'react';
import WelcomeImage from "../writingHeader.jpg";
import overlayImage from "../../commonImg/overlay2-50.svg";
function Header({title}) {

    const heroStyle={
        backgroundImage:`url(${WelcomeImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment:'initial'
    };
    const overlay={
        backgroundImage:`url(${overlayImage})`,
    };
    const headerStyle={
        textShadow:'1px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff'
    };
    return(
        <header className="hero hero-fluid hero-page txt-color-sasha-darkest" style={heroStyle}>
            <div style={overlay}>
                <div className="container nfloat-down">
                    <h1 className="txt-weight-black" style={headerStyle}>{title}</h1>{/*
                    <h5><span className="badge badge-sasha-darkest">dddd</span></h5>
                    <a className="btn btn-sasha-darkest-o" href="https://medium.com/@sashiksu" target="_blank" role="button" rel="noopener noreferrer">Read Other Writings</a>*/}
                </div>
            </div>
        </header>
    )
}

export default Header;
