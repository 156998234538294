import React from "react";
import moment from "moment";
function StackoverflowActivity({stackoverflowActivities}) {
    const stackoverflowDivStyle={
        wordWrap: 'break-word',
        overflow: 'auto',
        maxHeight: '350px',
        width:'100%'
    };
    const activityList=stackoverflowActivities && stackoverflowActivities.map(activity=>{
        var events=null;
           if(activity.timeline_type==='commented'){
               events=(
                   <div key={activity.comment_id}>
                       <p  className="txt-weight-light" align="justify">
                           <i className="far fa-comments"></i>
                           &nbsp;commented on
                           <b>&nbsp;<a href={"https://stackoverflow.com/questions/"+activity.post_id} target="_blank" rel="noopener noreferrer">{activity.title}</a>&nbsp;</b>
                           <i>
                               {moment((moment.unix(activity.creation_date).format("DD-MM-YYYY HH:mm:ss A")),'DD-MM-YYYY HH:mm:ss A').fromNow()}
                           </i>
                       </p>
                       <div >
                           <small>{activity.detail}</small>
                       </div>
                       <hr/>
                   </div>
               )
           }
        else if(activity.timeline_type==='answered'){
            events=(
               <div key={activity.post_id} >
                   <p className="txt-weight-light" align="justify">
                       <i className="fas fa-reply"></i>
                       &nbsp;answered on
                       <b>&nbsp;<a href="https://stackoverflow.com/" target="_blank" rel="noopener noreferrer">{activity.title}</a>&nbsp;</b>
                       <i>
                           {moment((moment.unix(activity.creation_date).format("DD-MM-YYYY HH:mm:ss A")),'DD-MM-YYYY HH:mm:ss A').fromNow()}
                       </i>
                   </p>
                   <hr/>
               </div>
            )
        } else if(activity.timeline_type==='revision'){
              events=(
                 <div  key={activity.post_id+activity.user_id} >
                     <p className="txt-weight-light" align="justify">
                         <i className="fas fa-edit"></i>
                         &nbsp;rivision on
                         <b>&nbsp;<a href="https://stackoverflow.com/" target="_blank" rel="noopener noreferrer">{activity.title}</a>&nbsp;</b>
                         <i>
                             {moment((moment.unix(activity.creation_date).format("DD-MM-YYYY HH:mm:ss A")),'DD-MM-YYYY HH:mm:ss A').fromNow()}
                         </i>
                     </p>
                     <hr/>
                 </div>
              )
           }else if(activity.timeline_type==='suggested'){
               return(
                   <div key={activity.suggested_edit_id}>
                       <p  className="txt-weight-light" align="justify">
                           <i className="fas fa-angle-double-right"></i>
                           &nbsp;suggested on
                           <b>&nbsp;<a href="https://stackoverflow.com/" target="_blank" rel="noopener noreferrer">{activity.title}</a>&nbsp;</b>
                           <i>
                               {moment((moment.unix(activity.creation_date).format("DD-MM-YYYY HH:mm:ss A")),'DD-MM-YYYY HH:mm:ss A').fromNow()}
                           </i>
                       </p>
                       <hr/>
                   </div>
               )
           }else if(activity.timeline_type==='badge'){
               events=(
                 <div key={activity.badge_id} >
                     <p className="txt-weight-light" align="justify">
                         <i className="fas fa-id-badge"></i>
                         &nbsp;earned <b><i>{activity.detail}</i></b>
                         &nbsp;badge&nbsp;
                         <i>
                             {moment((moment.unix(activity.creation_date).format("DD-MM-YYYY HH:mm:ss A")),'DD-MM-YYYY HH:mm:ss A').fromNow()}
                         </i>
                     </p>
                     <hr/>
                 </div>
               )
           }
           else if(activity.post_id==='accepted'){
               events=(
                  <div key={activity.creation_date} >
                      <p className="txt-weight-light" align="justify">
                          <i className="fas fa-check"></i>
                          &nbsp;accepted answer for   <b>&nbsp;<a href="https://stackoverflow.com/" target="_blank" rel="noopener noreferrer">{activity.title}</a>&nbsp;</b>
                          <i>
                              {moment((moment.unix(activity.creation_date).format("DD-MM-YYYY HH:mm:ss A")),'DD-MM-YYYY HH:mm:ss A').fromNow()}
                          </i>
                      </p>
                      <hr/>
                  </div>
               )
           }else if(activity.timeline_type==='asked'){
              events=(
                  <div key={activity.post_id} >
                      <p className="txt-weight-light" align="justify">
                          <i className="fas fa-question"></i>
                          &nbsp;asked question about
                          <b>&nbsp;<a href="https://stackoverflow.com/" target="_blank" rel="noopener noreferrer">{activity.title}</a>&nbsp;</b>
                          <i>
                              {moment((moment.unix(activity.creation_date).format("DD-MM-YYYY HH:mm:ss A")),'DD-MM-YYYY HH:mm:ss A').fromNow()}
                          </i>
                      </p>
                      <hr/>
                  </div>
              )
           }
           return events;
        }
    );
    return(
        <div id="stackoverflow-activity" style={stackoverflowDivStyle}>
            {activityList}
        </div>
    )
}
export default StackoverflowActivity;
