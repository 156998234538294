import React,{Component} from 'react';
import {Helmet} from "react-helmet";
import Header from "./header/header";
class PrivacyPolicy extends Component{
    render() {
        return(
            <div>
                <Helmet>
                    <title>Privacy Policy</title>
                    <meta
                        name="description"
                        content=""
                    />
                    <meta
                        name="keywords"
                        content=""
                    />
                </Helmet>
                <Header/>
                <div className="container">
                    <p>The data you have shared with us via forms and emails are not shared among third party !</p>
                </div>
            </div>
        )
    }
}
export default PrivacyPolicy