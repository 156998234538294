import axios from "axios";

export const getGithubActivities = async () => {
    try {
        const result = await axios.get("https://api.github.com/users/sashiksu/events/public");
        return result.data.slice(0, 20);
    }
    catch (err) {
        return [];
    }

}

export const getStackoverflowActivities = async () => {
    try {
        const result = await axios.get("https://api.stackexchange.com/2.2/users/5805507/timeline?site=stackoverflow");
        return result.data.items.slice(0, 20);
    }
    catch (err) {
        return [];
    }
}