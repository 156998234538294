import React from "react";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
function WebView({data,techStackGenerate}) {
    return(
        <ReactTable
            data={data}
            columns={[
                {
                    Header: "Name",
                    accessor: "project_name",
                    width:350
                },
                {
                    Header: "Tech Stack",
                    accessor: "technology_stack",
                    Cell:row => (
                        <div>{techStackGenerate(row.value)}</div>
                    )
                },
                {
                    Header:"",
                    accessor:"project_id",
                    width:60,
                    Cell: row => (
                        <div>
                            <Link to={"/project/" + row.value} className="btn btn-primary btn-sm"><i className="fa fa-info" aria-hidden="true"></i>&nbsp;Info</Link>
                        </div>
                    )
                }
            ]}
            defaultPageSize={5}
            className="-striped -highlight"
        />
    )
}
export default WebView;