import React from "react";
function Content({content}) {

    var originalHTML = content;
    var processedHTML = originalHTML.replace("<img", "<img className='img-fluid' width='100%' height='auto'");

    function createMarkup() {
       // console.log(processedHTML);
        return {__html: processedHTML};
    }

    function returnJSX() {
        return <div dangerouslySetInnerHTML={createMarkup()} />;
    }

    return(
        <div className="container">
            {returnJSX()}
        </div>
    )
}
export default Content
