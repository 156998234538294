import React from "react";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {uid} from "react-uid";

function MobileAndTabView({classesData,generateCuriousColumn,generateAnimateAvailableClass}) {

    const tableRowJSX=classesData && classesData.map(tr=>{
        return(
            <Tr key={uid(tr)}>
                <Td>{tr.className}</Td>
                <Td>{tr.classDescription}</Td>
                <Td>{generateAnimateAvailableClass(tr.availability) }</Td>
                <Td>{generateCuriousColumn(tr.availability,tr.classId,tr.className)}
                </Td>
            </Tr>
        );
    });
    return(
        <Table className="table table-striped table-bordered table-hover">
            <Thead>
                <Tr>
                    <Th>Class</Th>
                    <Th>Brief Intro</Th>
                    <Th>Enroll</Th>
                    <Th>Curious ?</Th>
                </Tr>
            </Thead>
            <Tbody>
                {tableRowJSX}
            </Tbody>
        </Table>
    )
}
export default MobileAndTabView