import React from "react";
import moment from "moment";
function GithubActivity({githubActivities}) {
    const githubDivStyle={
        wordWrap: 'break-word',
        overflow: 'auto',
        maxHeight: '350px',
        width:'100%'
    };
    const activityList= githubActivities && githubActivities.map(activity=>{
            let events;
            if(activity.type==='WatchEvent'){
                events= (
                    <div key={activity.id} >
                        <p className="txt-weight-light" align="justify">
                            <i className="far fa-eye"></i>
                            &nbsp;{activity.payload.action}  watch event
                            <b>&nbsp;<a href="https://github.com/" target="_blank" rel="noopener noreferrer">{activity.repo.name}</a>&nbsp;</b>
                            <i>
                                {moment(activity.created_at,'YYYY-MM-DD HH:mm:ss A').fromNow()}
                            </i>
                        </p>
                        <hr/>
                    </div>
                )
            } else if(activity.type==='IssueCommentEvent'){
              events=(
                  <div key={activity.id} >
                      <p className="txt-weight-light" align="justify">
                          <i className="fas fa-bug"></i>
                          &nbsp;{activity.payload.action}  comment on issue of
                          <b>&nbsp;<a href="https://github.com/" target="_blank" rel="noopener noreferrer">{activity.repo.name}</a>&nbsp;</b>
                          <i>
                              {moment(activity.created_at,'YYYY-MM-DD HH:mm:ss A').fromNow()}
                          </i>
                      </p>
                      <div >
                          <small>{activity.payload.comment.body}</small>
                      </div>
                      <hr/>
                  </div>
              )
            }else if(activity.type==='IssuesEvent'){
              events=(
                  <div  key={activity.id} >
                      <p className="txt-weight-light" align="justify">
                          <i className="fas fa-exclamation-triangle"></i>
                          &nbsp;{activity.payload.action}  issue event
                          <b>&nbsp;<a href="https://github.com/" target="_blank" rel="noopener noreferrer">{activity.repo.name}</a>&nbsp;</b>
                          <i>
                              {moment(activity.created_at,'YYYY-MM-DD HH:mm:ss A').fromNow()}
                          </i>
                      </p>
                      <hr/>
                  </div>
              )
            }else if(activity.type==='CreateEvent'){
                events=(
                   <div key={activity.id} >
                       <p className="txt-weight-light" align="justify">
                           <i className="far fa-calendar"></i>
                           &nbsp;{activity.payload.action}  create event
                           <b>&nbsp;<a href="https://github.com/" target="_blank" rel="noopener noreferrer">{activity.repo.name}</a>&nbsp;</b>
                           <i>
                               {moment(activity.created_at,'YYYY-MM-DD HH:mm:ss A').fromNow()}
                           </i>
                       </p>
                       <hr/>
                   </div>
                )
            }else if(activity.type==='PublicEvent'){
                events=(
                   <div key={activity.id}>
                       <p  className="txt-weight-light" align="justify">
                           <i className="fas fa-upload"></i>
                           &nbsp; made private repo public
                           <b>&nbsp;<a href="https://github.com/" target="_blank" rel="noopener noreferrer">{activity.repo.name}</a>&nbsp;</b>
                           <i>
                               {moment(activity.created_at,'YYYY-MM-DD HH:mm:ss A').fromNow()}
                           </i>
                       </p>
                       <hr/>
                   </div>
                )
            }
            return events;
        }
    );
    return(
        <div id="github-activity" style={githubDivStyle}>
            {activityList}
        </div>
    )
}
export default GithubActivity;
