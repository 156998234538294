import React from "react";
function Areas(){

        return (
            <div className="home-component" id="passion">
                <div className="container">
                    <h2 className="txt-color-primary">What I’m Passionate About,</h2>
                    <div className="row">
                        <div className="col-md-3">
                            <img className="img-fluid" src="assets/images/skill-icons/textile-screen.svg" alt="Mobile Apps"/>
                            <center><h5 className="txt-weight-medium txt-color-primary">Teaching</h5></center>
                           {/* <p align="justify">
                                I liked to teach Computer Science subjects to others.
                                Check <a href="/classes">my classes</a> if you interested !
                            </p>*/}
                        </div>

                        <div className="col-md-3">
                            <img className="img-fluid" src="assets/images/skill-icons/monitor.svg"
                                 alt="Full-Stack Web"/>
                           <center> <h5 className="txt-weight-medium txt-color-primary">Full-Stack</h5></center>
                           {/* <p align="justify">
                                Do you want to build standalone, web or mobile app for your business ? My team is ready to
                                deliver your product.
                                <a href="mailto:sashiksu@gmail.com?subject=To Arrange Meeting For Discuss My Software Solution">
                                Contact</a> me for arrange a meeting.
                            </p>*/}
                        </div>

                        <div className="col-md-3">
                            <img className="img-fluid" src="assets/images/skill-icons/nodes.svg" alt="Algorithms"/>
                           <center> <h5 className="txt-weight-medium txt-color-primary">Write</h5></center>
                            {/*<p align="justify">
                                I'm much interested in history, and I want to see those historic event in new eye and share it with you.
                                So I write about history. Besides history, I'm writing tech related articles.
                                You can <a href="/writings">check</a> those.
                            </p>*/}
                        </div>

                        <div className="col-md-3">
                            <img className="img-fluid" src="assets/images/skill-icons/mountains.svg" alt="Graphics"/>
                           <center> <h5 className="txt-weight-medium txt-color-primary">Planning</h5></center>
                           {/* <p align="justify">
                                I like to plan scalable systems and their functionalities while simplifying to simplest components.
                                Also planning database to systems. And implement such systems mixing multiple technologies.
                            </p>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
}
export default Areas