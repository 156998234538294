import React, { Component } from 'react';
import Header from "./header";
import "react-table/react-table.css";
import TableGroup from "./tableGroup";
import ProjectData from '../projectsData.json'

class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ProjectData.projects,
            projectType: ''
        };
    }
    componentDidMount() {
        let project_type = this.props.match.params.project_type;
        let filtered_data = this.state.data;
        filtered_data = this.state.data.filter(dataa => {
            return dataa.project_type === project_type
        });
        this.setState({
            data: filtered_data,
            projectType: project_type
        });
    };

    render() {
        const { data } = this.state;
        const projectTableGroupJSX = (
            <TableGroup data={data} />
        );
        return (
            <div>
                <Header projectType={this.state.projectType} />
                <div className="container">
                    <h1>My {this.state.projectType.charAt(0).toUpperCase() + this.state.projectType.substring(1)} Projects</h1>
                    {projectTableGroupJSX}
                </div>
            </div>
        );
    }
}

export default ProjectList;