import React,{Component} from 'react';
import Header from "./header";
import ProjectType from "./projectType";
import ProjectBackground from "./grey.png";
import {Helmet} from "react-helmet";
import ProjectHeaderImage from "./projectHeader.jpg";
import ProjectData from './projectsData.json'

class Projects extends Component{
    render() {

        const projectBackgroundStyle={
            backgroundImage:`url(${ProjectBackground})`
        };

        return(
            <div>
                <Helmet>
                    <title>Sashika Suraweera - Projects</title>
                    <meta name="description" content="Want to learn code ? Just join to my class" />
                    <meta name="keywords" content="sashika suraweera's Projects, portfolio, web designs, standalone applications, desktop applications" />
                    <meta property="og:title" content="Sashika Suraweera - Projects"/>
                    <meta property="og:url" content="https://sashiksu.com/projects"/>
                    <meta property="og:image" content={ProjectHeaderImage}/>
                </Helmet>
                <Header/>
                <div style={projectBackgroundStyle}>
                    <ProjectType projectTypes={ProjectData.project_types} recentProjects={ProjectData.projects}/>
                </div>
            </div>
        )
    }
}

export default Projects;
