import React from 'react';
import StackoverflowActivity from "./activitySO";
import loadingImage from "../common/img/loading.gif";
function Stackoverflow ({activities,loading}){
        let activitySO;
        if(loading===false){
            activitySO=(
                <div className="container">
                    <h2><i className="fab fa-stack-overflow"></i>&nbsp;Stackoverflow,</h2>
                    <br/>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <img src={loadingImage} width="160" height="160" alt="loading gif animation"/>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            )
        }
        else{
            activitySO=(
                <div className="container">
                    <h2><i className="fab fa-stack-overflow"></i>&nbsp;Stackoverflow,</h2>
                    <br/>
                    <StackoverflowActivity stackoverflowActivities={activities}/>
                </div>
            )
        }return activitySO;
    }
export default Stackoverflow;