import React,{Component} from "react";
//daytime icons
import nodeJS from "./img/currentStack/nodejs.svg";
import js from "./img/currentStack/js.svg";
import dotnet from "./img/currentStack/net.svg";
import react from "./img/currentStack/react.svg";
import sqlServer from "./img/currentStack/msql_server.svg";
import mySQL from "./img/currentStack/mysql.svg";
//nighttime icons
import aws from "./img/currentStack/aws.svg";
import django from "./img/currentStack/django.svg";
import ruby from "./img/currentStack/ruby.svg";
import angular from "./img/currentStack/angular.svg";
import java from "./img/currentStack/java.svg";
import mongo from "./img/currentStack/mongodb.svg";
class CurrentlyUsing extends Component{
    render(){
        return(
            <div className="home-component" id="currently-using">

                <div className="container">
                    <hr/>
                    <div id="technologies" className="section__component --theme-none --grid">
                        <div className="__container --left-align">
                            <div className="section__title">Day-time developer</div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={nodeJS} alt="node js"/>
                                    </div>
                                    {/* <span className="tile__content-title">Node</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={js} alt="js"/>
                                    </div>
                                    {/*  <span className="tile__content-title">JS</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={dotnet} alt=".net"/>
                                    </div>
                                    {/*<span className="tile__content-title">TypeScript</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={react} alt="react"/>
                                    </div>
                                    {/*<span className="tile__content-title">React</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={sqlServer} alt="sql server"/>
                                    </div>
                                    {/*<span className="tile__content-title">SQL Server</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={mySQL} alt="mysql"/>
                                    </div>
                                    {/*<span className="tile__content-title">MySQL</span>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="section__component --spaced --large --theme-negative --accent-blue --grid">
                        <div className="__container --right-align">
                            <div className="section__title">Night-time explorer</div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={ruby} alt="ruby"/>
                                    </div>
                                    {/*<span className="tile__content-title">Linux</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={django} alt="django"/>
                                    </div>
                                    {/*<span className="tile__content-title">Docker</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={aws} alt="aws"/>
                                    </div>
                                    {/*<span className="tile__content-title">Go</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={angular} alt="angular"/>
                                    </div>
                                    {/*<span className="tile__content-title">Node.js</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={java} alt="angular"/>
                                    </div>
                                    {/*<span className="tile__content-title">React</span>*/}
                                </div>
                            </div>
                            <div className="tile__component">
                                <div className="tile__content">
                                    <div className="tile-icon__component">
                                        <img className="tile-icon__image img-fluid" src={mongo} alt="angular"/>
                                    </div>
                                    {/*<span className="tile__content-title">Typescript</span>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
        )
    }
}
export default CurrentlyUsing;
