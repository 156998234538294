import React,{Component} from 'react';
import Header from "./header";
import Content from "./content";
import backgroundImage from "./linedpaper.svg";
import {Helmet} from "react-helmet";
import WritingsHeaderImage from "./writingHeader.jpg";
class Writings extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const backgroundStyle={
            backgroundImage:`url(${backgroundImage})`,
        };
        return(
            <div style={backgroundStyle}>
                <Helmet>
                    <title>Sashika Suraweera On Medium</title>
                    <meta
                        name="description"
                        content="Sashika Suraweera's blog posts on medium."
                    />
                    <meta
                        name="keywords"
                        content="blog, posts, medium posts"
                    />
                </Helmet>
                <Helmet>
                    <title>Sashika Suraweera - Writings</title>
                    <meta name="description" content="Want to learn code ? Just join to my class" />
                    <meta name="keywords" content="Sashika Suraweera's blog posts Articles" />
                    <meta property="og:title" content="Sashika Suraweera - Writings"/>
                    <meta property="og:url" content="https://sashiksu.com/writings"/>
                    <meta property="og:image" content={WritingsHeaderImage}/>
                </Helmet>
                <Header/>
                <Content/>
            </div>
        )
    }
}
export default Writings;
