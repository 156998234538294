import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
function WebViewTable ({classesData,generateCuriousColumn,generateAnimateAvailableClass}){
        return (
            <ReactTable
                data={classesData}
                filterable
                columns={[
                    {
                        Header: "Id",
                        accessor: "classId",
                        show: false,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["classId"]}),
                        filterAll: true
                    },
                    {
                        Header: "Class",
                        accessor: "className",
                        style: {'whiteSpace': 'unset'},
                        width: 200,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["className"]}),
                        filterAll: true
                    },
                    {
                        Header: "Brief Intro",
                        accessor: "classDescription",
                        style: {'whiteSpace': 'unset'},
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["classDescription"]}),
                        filterAll: true
                    },
                    {
                        Header: "Enroll",
                        accessor: "availability",
                        width: 110,
                        Cell: row=> generateAnimateAvailableClass(row.value)
                    },
                    {
                        Header: "Curious ?",
                        id: "Curious ?",
                        accessor: row => `${row.className} ${row.classId} ${row.availability}`,
                        width:170,
                        Cell: row => generateCuriousColumn(row.row.availability,row.row.classId,row.row.className)
                    }
                ]}
                defaultPageSize={5}
                className="-striped -highlight"
            />
        )
}
export default WebViewTable