import React from 'react';
import { Link } from "react-router-dom";
function Copyright() {
    return (
        <div className="copyright">
            <p className="txt-align-c no-margin-b txt-color-sasha-dark">
                © 2015 – {(new Date().getFullYear())} Sashika Suraweera -
                Last Build @ 22:32 : 16/07/2022
                <br />
                <Link to="/privacy">Privacy Policy</Link>
            </p>
        </div>
    )
}
export default Copyright

