import React, { Component } from 'react'
import Header from "./header";
import Stackoverflow from "./stackoverflow";
import Github from "./github";
import { getGithubActivities, getStackoverflowActivities } from "./methods";
import { Helmet } from "react-helmet";

class Now extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stackoverflowLoading: false,
            githubLoading: false,
            githubActivities: [],
            stackoverflowActivities: [],
        };
    }

    componentDidMount = async () => {
        const githubResults = await getGithubActivities();
        const stackoverflowResults = await getStackoverflowActivities();

        if (githubResults && stackoverflowResults) {
            this.setState({
                githubLoading: true,
                stackoverflowLoading: true,
                githubActivities: githubResults,
                stackoverflowActivities: stackoverflowResults,
            })
        }
    }


    render() {
        const { githubActivities, stackoverflowActivities, githubLoading, stackoverflowLoading } = this.state;
        return (
            <div>
                <Helmet>
                    <title>Sashika Suraweera Now</title>
                    <meta
                        name="description"
                        content="Sashika Suraweera's recent activities"
                    />
                    <meta
                        name="keywords"
                        content="stackoverflow and github activities"
                    />
                </Helmet>
                <Header />
                <div className="page-component nfloat-up">
                    <Stackoverflow activities={stackoverflowActivities} loading={stackoverflowLoading} />
                    <Github activities={githubActivities} loading={githubLoading} />
                </div>
            </div>
        )
    }
}

export default Now;