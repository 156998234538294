import React from "react";
import Particles from "react-particles-js";

export default () => (
    <div
        style={{
            position: "absolute",
            top: 0,
            width: "100%"
        }}
    >
        <Particles
            params={
                {
                    "particles": {
                        "number": {
                            "value": 20
                        },
                        "size": {
                            "value": 3
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onhover": {
                                "enable": false,
                                "mode": "repulse"
                            }
                        }
                    }
                }
            }
            height={250}
        />
    </div>
);
